import React, {useEffect, useState} from 'react';
import Button from '../components/Button';
import CustomerCard from '../components/CustomerCard';
import Layout from '../components/layout/Layout';
import {products} from '../data/customer-data';
import {Link} from "gatsby"

import style from '../css/product.css'


const Index = ({location, data}) => {

    const [product, setProduct] = useState({})

    useEffect(()=>{
        if(location.state?.id) {
            const id = location.state.id;
            products.forEach(p => {
                if(p.id === id) {
                    setProduct(p)
                }
        })}
    }, []);

  return (
  <Layout>
    <section id="stats" className="py-20">
      <div className='container mx-auto '>
        <div className='text text-4xl font-semibold ml-4'>{product?.title}</div>
      </div>
    </section>
    <section id="features" className="container mx-auto py-20 lg:pb-40 lg:pt-8">
    
        <h3 className="text-3xl font-semibold">{product?.short}</h3>
        <div className='flex flex-col md:flex-row pt-8'>
        
            <div className="container pr-4">
                {product?.description?.map(paragraph => {
                    return <p className="text-md pb-6 font-light text-gray-600">
                        {paragraph}
                    </p>
                })}
            </div>
            <div className='image-container mb-8'>
                <img src={product.productImage}></img>
            </div>
        </div>

      <div className='catalogues'>
      <h3 className="text-3xl font-semibold">Proizvodi</h3>
      {product?.products?.map(p => {
            return <div className='w-full mt-4 text-md'>
                <Link to="/proizvod"  state={{id: p.id}} className="mt-8 text-md font-normal text-primary">
                    <span className="font-bold">{p.name}</span> - {p.short}
                </Link>
                </div>
        })}
      </div>
        <p className="mt-8">
            <a className="px-4" href="/kontakt">
                <Button size="md" border>Kontaktirajte nas</Button>
            </a>
        </p>
    </section>
  </Layout>)
}

export default Index;
